<template>
  <div>
    <div class="txt-con" v-if="$route.query.aboutId === '1' || !$route.query.aboutId ">
      <p class="tex-index">焱十一致力于全球艺术与文化的融合和传播、打造多元化的艺术社区，为艺术从业者、收藏家和爱好者构建更专业、更高效、多品类、有品质保证的综合性线上拍卖交易平台。</p>
      <p class="tex-index">
        平台凭借精湛的网络科技、专业的服务、严谨的工作态度，联合全球优质拍卖行，提供中国古董艺术、珠宝腕表、艺术家居、日本及其他亚洲艺术、以及各种趣味收藏的海量精品，生货频出，同时大数据精准推送，让国内的买家足不出户、一键直达心仪的拍品，轻松同步参与海内外的优选拍卖会。
      </p>
    </div>
    <div class="content" v-if="$route.query.aboutId === '2' || !$route.query.aboutId">
      <p class="tex-index">服务特色：</p>
      <p class="tex-index">1、【跨境拍卖，收藏无界】汇集全球优质拍卖行和优选拍卖会，足不出户即可全球寻宝。</p>
      <p class="tex-index">2、【品类多样，中西合壁】汇集品类丰富的中、西方艺术品和收藏品，总有一款是您想要的。</p>
      <p class="tex-index">3、【海量信息，精准推送】海量拍品，生货频出，极速检索，大数据推送，轻松捡漏。</p>
      <p class="tex-index">4、【优质排行，一触即达】具有影响力的优质拍卖行和权威艺术机构签约入驻，提供有品质保障的拍卖会。</p>
      <p class="tex-index">5、【技术精湛，易于操作】平台提供网页、APP、微信及移动端等多个终端，友好的产品体验，前沿的互联网技术，让您随时、随地、随心同步参拍</p>
      <p class="tex-index">6、【安全支付，省心省事】资金安全，跨境支付更便捷、更省事、更轻松。</p>
      <p class="tex-index">7、【专业物流，安全高效】联手专业的艺术品物流机构，提供更安全、更高效、更省心、更省钱的物流服务。</p>
    </div>

  </div>
</template>
<script>
export default {
  name: 'aboutUs'
}
</script>
<style lang="less" scoped>
div {
  padding: 20px 80px;
  font-size: 15px;
  .tex-index {
    text-align: left;
    text-indent: 2em;
  }
}
</style>
